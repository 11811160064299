import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PreLoaderComponent } from './preloader.component';

@NgModule({
    declarations: [PreLoaderComponent],
    imports: [CommonModule],
    exports: [PreLoaderComponent],
})
export class PreloaderModule {}

<tui-theme-night *ngIf="theme === 'onDark'"></tui-theme-night>
<!--<app-preloader [preload]="(store.init$ | async) === false"></app-preloader>-->
<ion-app *ngIf="(store.init$ | async) === true">
  <tui-root [tuiMode]="theme">
    <ion-router-outlet></ion-router-outlet>
  </tui-root>
</ion-app>

<ion-action-sheet *tuiLet="actionSheet$ | async as actionSheet"
                  [header]="actionSheet.title"
                  [isOpen]="actionSheet.open"
                  (didDismiss)="actionSheet.fn($event)"
                  [buttons]="actionSheet.buttons || []"></ion-action-sheet>

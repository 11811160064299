import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionService {


  public actionSheetButtons = [
    {
      text: 'Delete',
      role: 'destructive',
      data: {
        action: 'delete',
      },
    },
    {
      text: 'Cancel',
      role: 'cancel',
      data: {
        action: 'cancel',
      },
    },
  ];

  actionSheetDefault = {
    title: '',
    buttons: this.actionSheetButtons,
    open: false,
    fn: () => {
    }
  }

  actionSheet$: BehaviorSubject<any> = new BehaviorSubject<any>(this.actionSheetDefault);

  constructor() {
  }

  openDeleteActionSheet(fn: () => any) {
    const actionSheet = {
      title: 'Delete',
      buttons: this.actionSheetButtons,
      open: true,
      fn
    }
    this.actionSheet$.next(actionSheet);
  }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/shared/modules/login/login.component';
import { AuthGuardGuard } from './core/guards/authGuard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  { path: 'login',
    canActivate: [AuthGuardGuard],
    component: LoginComponent },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'create',
    loadChildren: () => import('./create-request/create-request.module').then(m => m.CreateRequestModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome-pages/welcome-pages.module').then(m => m.WelcomePagesModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Animation, AnimationController } from '@ionic/angular';

export const enterAnimation = (baseEl: HTMLElement, opts?: any): Animation => {
  const animationCtrl = new AnimationController();
  const direction = (opts.direction === 'forward') ? 1 : -1;

  const rootTransition = animationCtrl.create()
    .duration(opts.duration || 350)
    .easing('cubic-bezier(0.3, 0.6, 0.2, 1)');

  const enteringPage = animationCtrl.create()
    .addElement(opts.enteringEl)
    .beforeRemoveClass('ion-page-invisible');

  const leavingPage = animationCtrl.create()
    .addElement(opts.leavingEl)
    .afterAddClass('ion-page-hidden');

  if (direction === 1) { // forward
    enteringPage.fromTo('transform', 'translateX(100%)', 'translateX(0%)');
    leavingPage.fromTo('transform', 'translateX(0%)', 'translateX(-100%)');
  } else { // back
    enteringPage.fromTo('transform', 'translateX(-100%)', 'translateX(0%)');
    leavingPage.fromTo('transform', 'translateX(0%)', 'translateX(100%)');
  }

  enteringPage.fromTo('opacity', 0.01, 1);
  leavingPage.fromTo('opacity', 1, 0.01);

  rootTransition.addAnimation([enteringPage, leavingPage]);
  return rootTransition;
};

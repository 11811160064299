<ion-content [fullscreen]="true">
  <script src="https://accounts.google.com/gsi/client" async></script>
  <div [formGroup]="registerForm" class="ion-margin">
    <tui-input
      class="tui-space_vertical-2"
      formControlName="email"
    >Маил
    </tui-input>
    <tui-input
      class="tui-space_vertical-2"
      formControlName="password"
    >Пароль
    </tui-input>
    <tui-input
      class="tui-space_vertical-2"
      formControlName="name">Имя
    </tui-input>
    <div class="display-flex flex-column gap-12px">
      <button
        tuiButton
        type="button"
        appearance="accent"
        class="tui-space_vertical-2"
        (click)="onSubmit()">
        Зарегать по почте и паролю
      </button>
      <button
        tuiButton
        type="button"
        appearance="primary"
        class="tui-space_vertical-2"
        (click)="onLogin()">
        Зайти
      </button>
      <button
        tuiButton
        type="button"
        appearance="outline"
        class="tui-space_vertical-2"
        (click)="signIn()">
        Войти google
      </button>
    </div>
    <div class="ion-margin">
      {{ (user$ | async)?.name }}
    </div>

  </div>
</ion-content>

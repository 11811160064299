import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import {
  TuiRootModule,
  TuiDialogModule,
  TuiAlertModule,
  TUI_SANITIZER,
  TuiThemeNightModule,
  TuiModeModule, TuiButtonModule
} from "@taiga-ui/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { PreloaderModule } from './core/shared/modules/preloader/preloader.module';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { enterAnimation } from './core/shared/animation';
import { TuiLetModule } from '@taiga-ui/cdk';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { environment } from '../environments/environment';

const config: SocketIoConfig = { url: environment.url, options: {} };

// you can also use dynamic webpack imports with hints for this
registerLocaleData(localeDE);
registerLocaleData(localeEN);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      statusTap: true,
      swipeBackEnabled: true,
      infiniteLoadingSpinner: 'circles',
      navAnimation: enterAnimation
    }),
    IonicStorageModule.forRoot(),
    SocketIoModule.forRoot(config),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TuiRootModule,
    TuiDialogModule,
    TuiThemeNightModule,
    TuiModeModule,
    TuiAlertModule,
    TuiButtonModule,
    PreloaderModule,
    TuiLetModule,
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, {
    provide: TUI_SANITIZER,
    useClass: NgDompurifySanitizer
  },
    {
      provide: APP_BASE_HREF,
      useFactory: (): string => {
        const locale =
          window.location.pathname.match(/^\/([A-z]{2})(\/|$)/)?.[1];
        return locale ? `/${locale}/` : '/';
      },
    },
    {
      provide: LOCALE_ID,
      useFactory: () => sessionStorage.getItem('app-locale'),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
}

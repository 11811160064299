import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { IUser } from '../../../services/model';
import { IonicModule, Platform } from '@ionic/angular';
import { StorageService } from '../../../services/storage.service';
import { TuiAlertService, TuiButtonModule } from '@taiga-ui/core';
import { AuthService } from '../../../services/auth.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { TuiInputModule } from '@taiga-ui/kit';
import { AsyncPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [
    TuiButtonModule,
    IonicModule,
    ReactiveFormsModule,
    TuiInputModule,
    AsyncPipe
  ]
})
export class LoginComponent implements OnInit {

  registerForm: FormGroup;
  user$: BehaviorSubject<IUser | null> = this.authService.user$;

  private CLIENT_ID = '317939871528-536clj0r3qo1b4g5aim55ut8sa5oi2bb.apps.googleusercontent.com';
  private SCOPE = 'email profile openid';

  constructor(public platform: Platform,
              private router: Router,
              private route: ActivatedRoute,
              private storageService: StorageService,
              @Inject(TuiAlertService) private readonly alerts: TuiAlertService,
              private authService: AuthService,
              private fb: FormBuilder) {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      name: ['', [Validators.required]]
    });
    this.initializeApp();
  }

  ngOnInit(): void {

    window.addEventListener('message', (event) => {
      // Убедитесь, что сообщение приходит с правильного источника
      // if (event.origin !== 'https://yourapi.com') {
      //   return;
      // }

      const tokenData = event.data;
      if (tokenData.token) {
        console.log('Received token:', tokenData.token);
        // Сохраняем токен в localStorage или sessionStorage
        localStorage.setItem('access_token', tokenData.token);
        // Выполняем перенаправление на защищенную страницу
        window.location.href = '/tabs/tab4';
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      GoogleAuth.initialize();
    })
  }

  onSubmit() {
    if (this.registerForm.valid) {
      this.authService.register(this.registerForm.value).subscribe(
        data => console.log('Success!', data),
        error => console.error('Error!', error)
      );
    }
  }

  async onLogin() {
    if (this.registerForm.value.email && this.registerForm.value.password) {
      try {
        await this.authService.login(this.registerForm.value);
        window.location.reload();
      } catch(e) {
      }

    }
  }

  logOut() {
    this.authService.logout().then();
  }

  signInWithGoogle(): void {
    const clientId = '317939871528-536clj0r3qo1b4g5aim55ut8sa5oi2bb.apps.googleusercontent.com';
    const redirectUri = 'https://api.argguide.com/auth/google/callback'; // Этот URL будет обработан на сервере
    const responseType = 'token'; // Мы используем Authorization Code Flow
    const scope = 'email profile openid';
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${responseType}&scope=${scope}&prompt=consent`;

    // Открываем всплывающее окно для авторизации
    const width = 500;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const popup = window.open(authUrl, 'GoogleAuthPopup', `width=${width},height=${height},top=${top},left=${left}`);

    if (popup) {
      // Можно отслеживать, когда окно будет закрыто
      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer);
          console.log('Popup closed by user');
          // Здесь можно выполнить действия после закрытия окна
        }
      }, 500);
    }
  }

  async signIn() {
    try {
      const user = await GoogleAuth.signIn();
      const response = await this.authService.loginGoogle(user).toPromise();
      this.storageService.set('auth_token', response?.token);
      await this.authService.getProfile(response?.token);
      this.router.navigate(['']);

    } catch(e) {
      console.log(e);
    }
  }


}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { StoreService } from './store.service';
import { ICountryNameKey, IUser } from './model';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = `${environment.url}/api/auth`; // URL вашего API
  private token!: string;

  lang: ICountryNameKey = 'en';
  user$: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);

  constructor(private http: HttpClient,
              private router: Router,
              private store: StoreService,
              private storage: StorageService) {
  }

  getUserProfile(token: string): Observable<IUser> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<IUser>(`${this.apiUrl}/profile`, { headers });
  }

  register(user: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/register`, user);
  }

  async login(credentials: any) {
    const response = await this.http.post<any>(`${this.apiUrl}/login`, credentials).toPromise();
    if (response && response.token) {
      this.storage.set('auth_token', response?.token);
      // if (response.token) {
      //   const user = await this.getUserProfile(response?.token).toPromise();
      //   console.log(user)
      //   if (user) {
      //     this.user$.next(user)
      //   }
      // }
    }
    return response;
  }

  loginGoogle(credentials: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/google-auth`, credentials);
  }

  async isLoggedIn(): Promise<boolean> {
    this.token = await this.storage.get('auth_token');
    if (this.token) {
      try {
        await this.getProfile(this.token);
        this.store.init$.next(true);
        this.router.navigate(['']);
      } catch(error) {
      }
    }
    this.store.init$.next(true);
    return !!this.token;
  }

  async getProfile(token: string) {
    const data = await this.getUserProfile(token).toPromise();
    if (data) {
      this.user$.next(data);
    }
  }

  toggleFavorites(id: string) {
    const user = this.user$.getValue();
    if (user) {
      const idx = user.favorites.findIndex(value => value === id);
      if (idx >= 0) {
        user?.favorites.splice(idx, 1);
      } else {
        user?.favorites.push(id);
      }
      console.log(user)
      this.user$.next(user);
    }
  }

  get getToken() {
    return this.token;
  }

  async logout() {
    await this.storage.remove('auth_token');
    this.user$.next(null);
  }
}

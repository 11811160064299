import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss'],
})
export class PreLoaderComponent {
    public isHidden: boolean = false;
    public isLoad: boolean = false;

    @Input() set preload(value: boolean | null) {
        if (value) {
            this.isLoad = value;
        } else {
            this.isHidden = true;
            setTimeout(() => {
                // @ts-ignore
              this.isLoad = value;
                this.isHidden = false;
            }, 3000);
        }
    }

    constructor() {}
}

import { AfterViewInit, Component } from '@angular/core';
import { TuiBrightness } from '@taiga-ui/core';
import { register } from 'swiper/element/bundle';
import { StoreService } from './core/services/store.service';
import { StorageService } from './core/services/storage.service';
import { AuthService } from './core/services/auth.service';
import { ActionService } from './core/services/action.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {

  theme: TuiBrightness | null = 'onDark';

  actionSheet$ = this.actionService.actionSheet$;

  constructor(private authService: AuthService,
              public store: StoreService,
              private router: Router,
              private actionService: ActionService,
              private storage: StorageService) {
    this.init();
  }

  async init() {
    await this.storage.init();
    const isDark = await this.storage.get('theme') ?? false;
    this.setTheme(isDark as boolean);
    this.router.navigate(['']);

    const isLoggedIn = await this.authService.isLoggedIn();

    this.store.getState$('theme').subscribe(value => {
      this.setTheme(value as boolean);
    })
  }

  setTheme(isDark: boolean = true) {
    this.store.setState('theme', isDark)
    this.theme = isDark ? 'onDark' : null;
    this.toggleDarkPalette(isDark)
  }

  toggleDarkPalette(shouldAdd: boolean) {
    document.documentElement.classList.toggle('ion-palette-dark', shouldAdd);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      register();
    }, 0)
  }

  fnAction($event: any) {
    this.actionSheet$.value.fn($event);
  }
}
